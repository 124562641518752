import Container from '@components/Container';
import Layout from '@components/Layout';
import LayoutTopSection from '@components/LayoutTopSection';
import Paragraph from '@components/Typography/Paragraph';
import error404 from '@images/pages/404.svg';
import { phoneNumberPrzemek } from '@utils/variables';
import React from 'react';

const Page404 = () => {
  const renderContent = () => (
    <>
      <Paragraph mbottom={1}>Strona w budowie. Nie wszystkie treści są jeszcze dostępne</Paragraph>
      <Paragraph>
        Wróć do poprzedniej strony lub skontaktuj się z biurem pod numerem telefonu:
        {` `}
        <a href={`tel:${phoneNumberPrzemek}`}>{phoneNumberPrzemek}</a>
      </Paragraph>
    </>
  );

  const renderSiteImg = () => <img src={error404} alt="" width="100%" />;
  return (
    <Layout>
      <Container>
        <LayoutTopSection
          title="Oops!"
          subtitle="Przepraszamy za nieudogodnienia"
          content={renderContent()}
          rightSide={renderSiteImg()}
        />
      </Container>
    </Layout>
  );
};

export default Page404;
