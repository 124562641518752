import { DEVICE } from '@assets/const';
import Heading from '@components/Typography/Heading';
import Paragraph from '@components/Typography/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { Hidden, Visible } from 'react-grid-system';
import styled from 'styled-components';

const { MD } = DEVICE;

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0 0 0;
  padding: 0 1rem;

  @media ${MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    margin: 5rem 0 0 0;
  }
`;

const FullWidth = styled.div`
  width: 100%;
`;

const VerticalLine = styled.div`
  width: 2px;
  background: linear-gradient(to bottom, #bd8604, #e7b80c, #f4d469);
  margin: 0 2rem 0 0;
`;

const ContentBox = styled.div`
  margin: 4rem 0 0 0;

  @media ${MD} {
    margin: 2rem 0 0 2rem;
  }
`;

const ContentBoxRightSide = styled.div`
  margin: 2rem 0 0 0;

  @media ${MD} {
    margin: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
`;

const FlexContent = styled.div`
  width: 100%;
`;

const LayoutTopSection = ({ title, subtitle, content, rightSide, showRightSideOnMobile }) => {
  return (
    <Wrapper>
      <Flex>
        <FullWidth>
          <Flex>
            <VerticalLine />
            <div>
              <Heading>{title}</Heading>
              {subtitle && <Paragraph gray>{subtitle}</Paragraph>}
            </div>
          </Flex>
          {content && <ContentBox>{content}</ContentBox>}
        </FullWidth>
      </Flex>
      <ContentBoxRightSide>
        {rightSide && (
          <Hidden xs sm>
            <FlexContent>
              <Flex>
                <FlexContent>{rightSide}</FlexContent>
              </Flex>
            </FlexContent>
          </Hidden>
        )}
        {showRightSideOnMobile && rightSide && (
          <Visible xs sm>
            <FlexContent>
              <Flex>
                <FlexContent>{rightSide}</FlexContent>
              </Flex>
            </FlexContent>
          </Visible>
        )}
      </ContentBoxRightSide>
    </Wrapper>
  );
};

LayoutTopSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.node,
  rightSide: PropTypes.node,
  showRightSideOnMobile: PropTypes.bool,
};

LayoutTopSection.defaultProps = {
  subtitle: null,
  content: null,
  rightSide: null,
  showRightSideOnMobile: false,
};

export default LayoutTopSection;
