import { DEVICE } from '@assets/const';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const { MD } = DEVICE;

const H1 = styled.h1`
  font-size: 2.5rem;
  font-family: 'Cormorant Garamond', serif;
  ${(props) => (props.mbottom ? `margin: 0 0 ${props.mbottom}rem 0` : 'margin: 0 0 2rem 0')};
  text-align: left;

  @media ${MD} {
    font-size: 3.5rem;
    ${(props) => props.center && 'text-align: center'};
  }
`;

const Heading = ({ children, center, mbottom }) => (
  <H1 center={center} mbottom={mbottom}>
    {children}
  </H1>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
  mbottom: PropTypes.number,
};

Heading.defaultProps = {
  center: false,
  mbottom: 0,
};

export default Heading;
